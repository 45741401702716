import Constants from "../constants/Constants";

class APISendBulkMail {
    constructor() {
        this.servers = [
            'tiemdo-marketing1.store',
            'tiemdo-marketing2.store',
            'tiemdo-marketing3.store',
            'tiemdo-marketing4.store',
            'tiemdo-marketing5.store',
            'tiemdo-marketing6.store',
            'tiemdo-marketing7.store',
            'tiemdo-marketing8.store',
            'tiemdo-marketing9.store',
            'tiemdo-marketing10.store',
        ];
    }

    async Request(emails, template, subject, selectedServers) {
        const mails = emails.split(',');
        const serversToUse = selectedServers.length > 0 ? selectedServers : this.servers;
        const serverCount = serversToUse.length;
        const batchSize = Math.ceil(mails.length / serverCount);
        const emailBatches = Constants.chunkArray(mails, batchSize);

        const sendPromises = emailBatches.map((batch, index) =>
            this.sendBatch(batch, template, subject, serversToUse[index % serverCount])
        );

        try {
            const results = await Promise.all(sendPromises);
            const totalSuccess = results.reduce((sum, result) => sum + (result?.success || 0), 0);
            const totalFail = results.reduce((sum, result) => sum + (result?.fail || 0), 0);
            const allFailedEmails = results.flatMap(result => result?.failed_emails || []);

            console.log(`Totaal verzonden e-mails: ${totalSuccess}, Mislukt: ${totalFail}`);
            console.log(`Mislukte e-mails: ${allFailedEmails.join(', ')}`);

            const isSuccess = totalSuccess > 0 || (totalSuccess === 0 && totalFail === 0);

            return {
                success: isSuccess,
                totalSent: totalSuccess,
                totalFailed: totalFail,
                failedEmails: allFailedEmails
            };
        } catch (err) {
            console.error('Fout bij het verzenden van e-mails:', err);
            return {
                success: false,
                totalSent: 0,
                totalFailed: mails.length,
                failedEmails: mails
            };
        }
    }

    async sendBatch(emails, template, subject, server) {
        try {
            const response = await fetch(`https://${server}/php/mail/bulk-email-sender.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    emails: emails,
                    template: template,
                    subject: subject
                })
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            console.log(`Server ${server} response:`, data);
            return data;
        } catch (err) {
            console.error(`Fout bij het verzenden van e-mails via ${server}:`, err);
            return null;
        }
    }
}

export default (new APISendBulkMail());